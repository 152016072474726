.report-link {
  text-decoration: none;
  color: #1976d2; /* 设置链接的初始颜色，您可以根据您的设计调整 */
  font-weight: bold;
  transition: color 0.3s; /* 平滑的颜色过渡 */
}

.report-link:hover {
  color: #0d47a1; /* 鼠标悬停时的颜色 */
  text-decoration: underline; /* 添加下划线以增强可点击的视觉效果 */
}