.dashboard-container2 {
  display: flex; /* Use flex to center children */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%;
  height: 100vh;
  overflow: auto; /* Scroll inside container if content is larger */
}
.dashboard-container2 iframe {
  width: 100%; /* 让iframe响应式 */
  height: 100%; /* 调整高度以填充容器 */
  border: 0; /* 移除边框 */
  overflow: hidden; /* 隐藏滚动条 */
}

/* Global styles for iframe container */
.dashboard-container {
  display: flex; /* Use flex to center children */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%;
  height: 100vh;
  overflow: auto; /* Scroll inside container if content is larger */
}


.dashboard-container iframe {
  width: 100%; /* 让iframe响应式 */
  height: 100%; /* 调整高度以填充容器 */
  border: 0; /* 移除边框 */
  overflow: hidden; /* 隐藏滚动条 */
  transition: transform 0.3s ease, left 0.3s ease, right 0.3s ease;
}

.desktop-center {
  transform: scale(1) translate(-50%, 0);
  transform-origin: top left;
  position: relative;
  left: 50%;
}

.mobile-right {
  transform: scale(0.9); /* 调整缩放比例 */
  transform-origin: top left; /* 确保缩放后右对齐 */
  position: absolute;
  right: -15;
  top: 10; /* 确保顶部没有空白 */
}







    /* Make sure flexDirection is correct and inside a style object */
.price-info {
      display: flex;
      flex-direction: column;
      height: 100vh;
      margin: 0;
}

/* Additional styles to ensure content doesn't overflow */
body, html {
  margin: 0; /* Remove default margins */
  padding: 0; /* Remove default paddings */
  overflow-x: hidden; /* Prevent horizontal scroll on the entire page */
}


